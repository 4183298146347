<template>
    <div class="refresh">
        
    </div>
</template>

<script>
export default {
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if(to.query){
                if(to.query.refresh){
                    vm.$router.replace({name:to.query.usernext,query:to.query.query})
                }else if(to.query.usernext){
                    vm.$router.replace({name:to.query.usernext})
                }else{
                    vm.$router.replace({name:from.name,query:to.query})
                }
            }else{
                vm.$router.replace(from.path)
            }
        })
    },
};
</script>

